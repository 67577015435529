import { ChecklistStatus, VehiclesChecklist } from '@common/interfaces';

export const getChecklistStatus = (
  checklist: VehiclesChecklist,
): { status: ChecklistStatus; statusName: string } => {
  const { criticalIssuesCount, issuesCount, submitted, todayDrivesCount } = checklist;

  const statusMap = [
    {
      condition: !submitted && todayDrivesCount === 0,
      status: ChecklistStatus.NOT_USED,
      statusName: 'Not used',
    },
    {
      condition: !submitted && todayDrivesCount > 0,
      status: ChecklistStatus.NOT_REPORTED,
      statusName: 'Not reported',
    },
    {
      condition: criticalIssuesCount >= 1,
      status: ChecklistStatus.CRITICAL,
      statusName: 'Critical Issues',
    },
    { condition: issuesCount >= 1, status: ChecklistStatus.ISSUES, statusName: 'Issues' },
  ];

  const matchedStatus = statusMap.find(({ condition }) => condition);

  return matchedStatus ?? { status: ChecklistStatus.CLEAR, statusName: 'Clear' };
};
