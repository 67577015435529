/* istanbul ignore file */
import {
  areIntervalsOverlapping,
  compareAsc,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parse,
} from 'date-fns';
import { PlDrive, ResViewType, TransferType } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { getFullDate } from '@common/utils';
import { DriveEditFormType, DriveFormType } from '../schema/DriveForm.schema';

// Booking Acceptance
export const checkConflictOnAccept = ({
  driver,
  drives,
  dropoffDate,
  dropoffTime,
  pickupDate,
  pickupTime,
  transferType,
  vehicle,
}: {
  driver?: string;
  drives: PlDrive[];
  dropoffDate?: Date;
  dropoffTime?: Date;
  pickupDate?: Date;
  pickupTime?: Date;
  transferType?: TransferType;
  vehicle?: string;
}): 'driver' | 'vehicle' | null => {
  if (!dropoffDate || !dropoffTime || !pickupDate || !pickupTime) return null;

  let conflict = null;

  const relevantDrives = drives.filter((d) => d.vehicleId === vehicle || d.driverId === driver);

  const selectedStart = getFullDate(pickupDate, pickupTime);
  const selectedEnd = getFullDate(dropoffDate, dropoffTime);

  if (!isValid(selectedStart) || !isValid(selectedEnd) || selectedStart >= selectedEnd) return null;

  relevantDrives.forEach((drive: PlDrive) => {
    if (transferType === TransferType.Shuttle && drive.scheduledRouteId !== null) return;

    const start = parse(`${drive.pickupDate} ${drive.pickupTime}`, DF.ApiDateAltFull, new Date());
    const end = parse(`${drive.dropoffDate} ${drive.dropoffTime}`, DF.ApiDateAltFull, new Date());

    if (!isValid(start) || !isValid(end) || start >= end) return;

    if (
      areIntervalsOverlapping({ start: selectedStart, end: selectedEnd }, { start, end }) &&
      !(drive.vehicleId === vehicle && drive.driverId === driver)
    ) {
      conflict = drive.vehicleId === vehicle ? 'vehicle' : 'driver';
    }
  });

  return conflict;
};

// Booking Edit
export const checkConflictDriveOnEdit = ({ drives }: DriveEditFormType): boolean => {
  if (drives.length <= 1) return false;

  const overlappingDrives = drives.map((drive: DriveFormType, idx: number) => {
    const comparedStart = getFullDate(drive.pickupDate, drive.pickupTime);
    const comparedEnd = getFullDate(drive.dropoffDate, drive.dropoffTime);

    if (!isValid(comparedStart) || !isValid(comparedEnd)) return false;

    return drives
      .filter((_, i: number) => i !== idx)
      .map((d: DriveFormType) => {
        const start = getFullDate(d.pickupDate, d.pickupTime);
        const end = getFullDate(d.dropoffDate, d.dropoffTime);

        if (compareAsc(comparedEnd, start) <= 0) return false;

        return areIntervalsOverlapping({ start: comparedStart, end: comparedEnd }, { start, end });
      });
  });

  return overlappingDrives.flat().includes(true);
};

// Drag & Drop
type DropParams = {
  drives: PlDrive[];
  start: Date;
  end: Date;
  resourceId: string;
  resourceType: ResViewType;
};

const getShuttleConfig = (shuttle: PlDrive, resourceType: ResViewType) => {
  const { driverId, vehicleId, pickupDate, pickupTime, dropoffDate, dropoffTime } = shuttle;
  const resource = resourceType === ResViewType.Vehicle ? vehicleId : driverId;
  const shuttleStart = parse(`${pickupDate} ${pickupTime}`, DF.ApiDateAltFull, new Date());
  const shuttleEnd = parse(`${dropoffDate} ${dropoffTime}`, DF.ApiDateAltFull, new Date());

  return { resource, shuttleStart, shuttleEnd };
};

export const checkOverlapEventAllow = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): boolean => {
  const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';
  const relevantDrives = drives.filter((drive) => drive[resKey] === resourceId);

  const overlap = relevantDrives.filter((drive) => {
    const driveStart = parse(drive.pickupTime, DF.ApiTimeFull, start);
    const driveEnd = parse(drive.dropoffTime, DF.ApiTimeFull, end);

    if (isAfter(driveStart, driveEnd)) return false;

    return isBefore(driveStart, end) && isAfter(driveEnd, start);
  });

  const resOverlapKey = resourceType === ResViewType.Driver ? 'vehicleId' : 'driverId';
  const hasMultipleOverlaps = overlap.length >= 2;
  const hasSameDriver = overlap.every((i) => i[resOverlapKey] === overlap[0][resOverlapKey]);

  return !(hasMultipleOverlaps && !hasSameDriver);
};

export const checkSharedDriveEvent = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): PlDrive | null => {
  const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';

  return (
    drives
      .filter((drive) => drive[resKey] === resourceId)
      .map((drive) => {
        const driveStart = parse(drive.pickupTime, DF.ApiTimeFull, start);
        const driveEnd = parse(drive.dropoffTime, DF.ApiTimeFull, end);

        return { drive, isOverlapping: isBefore(driveStart, end) && isAfter(driveEnd, start) };
      })
      .find(({ isOverlapping }) => isOverlapping)?.drive || null
  );
};

export const checkShuttleEventOverlap = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): boolean =>
  drives.some((shuttle) => {
    const { resource, shuttleStart, shuttleEnd } = getShuttleConfig(shuttle, resourceType);

    return (
      resource === resourceId &&
      (isWithinInterval(start, { start: shuttleStart, end: shuttleEnd }) ||
        isWithinInterval(end, { start: shuttleStart, end: shuttleEnd }) ||
        (isBefore(start, shuttleStart) && isAfter(end, shuttleEnd)))
    );
  });

export const checkShuttleWithinRange = ({
  drives,
  start,
  end,
  resourceId,
  resourceType,
}: DropParams): PlDrive | null =>
  drives.find((shuttle) => {
    const { resource, shuttleStart, shuttleEnd } = getShuttleConfig(shuttle, resourceType);

    return (
      resource === resourceId &&
      isWithinInterval(start, { start: shuttleStart, end: shuttleEnd }) &&
      isWithinInterval(end, { start: shuttleStart, end: shuttleEnd })
    );
  }) || null;
