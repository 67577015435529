import { ReactNode, useEffect, useRef } from 'react';

const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) callback();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};

interface OutsideAlerterProps {
  children: ReactNode;
  onClickOutside: () => void;
}

const OutsideClickWrapper = ({ children, onClickOutside }: OutsideAlerterProps) => {
  const wrapperRef = useOutsideClick(onClickOutside);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickWrapper;
