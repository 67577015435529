import axios from 'axios';
import {
  AvailableLocationsApi,
  FeatureFlags,
  IAvailableFacilitiesApi,
  IUserInfoApi,
} from '@common/interfaces';
import AxiosRepository from './axios.repository';

export interface AccountParams {
  agencyName?: string;
  facilityId?: string;
  proxy?: boolean;
}

export interface IAccountRepository {
  getAvailableFacilities: () => Promise<IAvailableFacilitiesApi[]>;
  getAvailableLocations: () => Promise<AvailableLocationsApi[]>;
  getFeatureFlags: (params?: AccountParams) => Promise<FeatureFlags>;
  getMonolithFeatureFlags: () => Promise<FeatureFlags>;
  getTranslations: (lang: string) => Promise<unknown>;
  getUser: (params?: AccountParams) => Promise<IUserInfoApi>;
  getUserProfile: (userEmail: string) => Promise<unknown>;
}

class AccountRepository extends AxiosRepository implements IAccountRepository {
  private buildParams(params?: AccountParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyName) searchParams.append('agency_short_name', params.agencyName);
    if (params?.facilityId) searchParams.append('facility_id_on_hbh', params.facilityId);
    if (params?.proxy) searchParams.append('proxy', 'true');

    return searchParams;
  }

  async getAvailableFacilities() {
    const { data } = await this.get('/facilities/available/');

    return data;
  }

  async getAvailableLocations() {
    const { data } = await this.get('/accounts/bookings/available-locations/');

    return data;
  }

  async getFeatureFlags(params?: AccountParams) {
    const { data } = await this.get('/feature-flags/', { params: this.buildParams(params) });

    return data;
  }

  async getMonolithFeatureFlags() {
    const { data } = await this.get(`${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`);

    return data;
  }

  async getTranslations(lang: string) {
    return this.get(`translations/web_app/${lang}/`);
  }

  async getUser(params?: AccountParams) {
    const { data } = await this.get('/accounts/me/', { params: this.buildParams(params) });

    return data;
  }

  // tocheck: this is never used anywhere
  async getUserProfile(userEmail: string) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_HBH_API}/v3/user-profile/auth-provider/`,
      {
        email: userEmail,
      },
      {
        headers: {
          Authorization: `Api-Key ${process.env.REACT_APP_HBH_API_KEY}`,
        },
      },
    );

    return data;
  }
}

export default AccountRepository;
