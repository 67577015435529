import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import {
  ChecklistTab,
  VehiclesChecklistDetails,
  VehicleChecklistDetailsApi,
} from '@common/interfaces';
import { Alert, NoActive, NoDisabled, YesActive, YesDisabled } from '@assets/svg/icons/checklist';
import { formatDateString, getErrors } from '@common/utils';
import { Serializer, getChecklistAnswers } from '@pages/VehiclesChecklistPage/utils';
import { DateFormat as DF } from '@common/types';
import { ChecklistTabs } from './components';
import './ChecklistDetailsPage.styles.scss';

const IconsContainer: FC<{ left: JSX.Element; right: JSX.Element }> = ({ left, right }) => (
  <div className="icons-container">
    {left}
    <div className="icons-divider" />
    {right}
  </div>
);

const ChecklistDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { vehicleChecklistRepo } = useRepository();
  const {
    agencyName,
    facility: { agencyId, country, city, uuid: facilityId },
    facilityId: facilityIdOnUNBH,
  } = useFacility();

  const [checklist, setChecklist] = useState<VehiclesChecklistDetails>();
  const [checklistTabs, setChecklistTabs] = useState<ChecklistTab[]>([]);
  const [selectedChecklistId, setSelectedChecklistId] = useState<string>(id || '');

  useQuery(
    ['get-checklist-details', selectedChecklistId],
    () => vehicleChecklistRepo.getChecklistDetails(facilityIdOnUNBH, selectedChecklistId),
    {
      onSuccess: (data: VehicleChecklistDetailsApi) =>
        setChecklist(Serializer.formatChecklistDetails(data)),
      onError: (err: any) => toast.error(getErrors(err.response)),
    },
  );

  const { mutateAsync: getRelatedChecklists } = useMutation<
    unknown,
    unknown,
    { currentChecklist: VehiclesChecklistDetails }
  >(['get-related-checklists-for-driver'], ({ currentChecklist }) => {
    const created = formatDateString(currentChecklist.created, DF.ApiDateAlt, DF.ApiDate);

    return vehicleChecklistRepo.getChecklists(facilityIdOnUNBH, {
      agencyId,
      createdAfter: created,
      createdBefore: created,
      driverId: currentChecklist.driverId!,
      locationId: facilityId,
    });
  });

  const getRelatedChecklistsForDriver = useCallback(
    async (checklistData: VehiclesChecklistDetails) => {
      const relatedChecklists = checklistData.driverId
        ? ((await getRelatedChecklists({ currentChecklist: checklistData })) as any)?.results || []
        : [];

      setChecklistTabs([
        { ...checklistData, main: true },
        ...relatedChecklists
          .filter((c: any) => c.id !== checklistData.id)
          .map((c: any) => ({ ...c, main: false })),
      ]);
    },
    [getRelatedChecklists],
  );

  useEffect(() => {
    if (checklist && facilityId && !checklistTabs.length) {
      getRelatedChecklistsForDriver(checklist);
    }
  }, [checklist, facilityId, getRelatedChecklistsForDriver, checklistTabs.length]);

  const driverName = useMemo(
    () =>
      checklist?.driverFirstName && checklist?.driverLastName
        ? `${checklist.driverFirstName} ${checklist.driverLastName}`
        : t('checklist.unknownDriver'),
    [checklist, t],
  );

  const answers = useMemo(() => getChecklistAnswers(checklist), [checklist]);

  const answerIcons = (answer: string, submitted: boolean) => {
    if (!submitted) return <IconsContainer left={<YesDisabled />} right={<NoDisabled />} />;
    return answer === 'yes' ? (
      <IconsContainer left={<YesActive />} right={<NoDisabled />} />
    ) : (
      <IconsContainer left={<YesDisabled />} right={<NoActive />} />
    );
  };

  return (
    <section className="page-container checklist-details-page">
      <div className="header">
        <h2 className="title">
          {driverName} - <span>{`${agencyName} ${country}, ${city}`}</span>
        </h2>
        <h3>
          {checklist?.created
            ? formatDateString(checklist.created, DF.ApiDateAlt, DF.InvoiceInfoDate)
            : null}
        </h3>
      </div>

      {checklistTabs.length ? (
        <ChecklistTabs checklistTabs={checklistTabs} onTabSelect={setSelectedChecklistId} />
      ) : null}

      <table className="table-checklist">
        <thead>
          <tr>
            <th>{t('checklist.checkInformation')}</th>
            <th>{t('common.status')}</th>
            <th>{t('common.note')}</th>
            <th>{t('common.photo')}</th>
            <th>{t('checklist.remarksFromManager')}</th>
          </tr>
        </thead>
        <tbody>
          {answers.map(({ answer, isCritical, imagesUrl, note, question }) => (
            <tr data-testid="checklist-answer" key={question}>
              <td>
                {isCritical && answer === 'no' && <Alert className="critical-icon" />} {question}
              </td>
              <td>{answerIcons(answer, checklist?.submitted ?? false)}</td>
              <td>{note}</td>
              <td>
                {imagesUrl?.map((img) => (
                  <img src={img} alt="answer-attachment" key={img} />
                ))}
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ChecklistDetailsPage;
