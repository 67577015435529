import type { FC } from 'react';
import { useAuth } from '@unbooking/ui-auth';
import { PoweredByLogo } from '@assets/svg/logos';
import { SUPPORT_EMAIL } from '@common/constants';
import { Button } from '@components';

const ErrorPage: FC = () => {
  const { error } = useAuth();

  const homeLink = '/secure/office';

  return (
    <div className="page-no-access">
      <div className="container">
        <div className="row">
          <PoweredByLogo width={75} height={75} />
          <h3 className="message">
            Ops, there was an error: {error ?? 'Server error'}. If you keep getting this error
            please contact support: <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </h3>
        </div>

        <div className="links">
          <Button
            className="link"
            text="Go to Homepage"
            variant="transparent"
            onClick={() => {
              window.location.href = homeLink;
            }}
          />
          <a className="link" href="mailto:noreply.humanitarianbooking@wfp.org">
            Contact us
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
