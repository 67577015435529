import { ReactNode } from 'react';
import { Tooltip as TooltipMantine, TooltipProps as TooltipMantineProps } from '@mantine/core';

type TooltipProps = Omit<TooltipMantineProps, 'label'> & { label: string | ReactNode };

const Tooltip = ({ children, label, ...props }: TooltipProps) => (
  <TooltipMantine arrowSize={6} label={label} multiline withArrow withinPortal {...props}>
    <div className="tooltip">{children}</div>
  </TooltipMantine>
);

export default Tooltip;
