/* istanbul ignore file */
export const LocalStorageKeys = {
  LOCATION: 'location',
};

export const SessionStorageKeys = {
  AGENCY: 'agency',
  AGENCY_ID: 'agencyId',
  FACILITY: 'facility',
};
