import { type FC, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Form, FormState } from 'informed';

import { TextField } from '@ui-modules/informed';
import { useAuth } from '@unbooking/ui-auth';
import { Loader } from '@components';
import { notEmptyValidator } from '@common/utils';
import { Button } from '@unbooking/ui-modules';

import './SignInPage.styles.scss';

const SignInPage: FC = () => {
  const { authorize } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  async function submitLoginForm({ email }: { email: string }) {
    setIsLoading(true);
    try {
      await authorize(email);
    } catch (err: any) {
      toast.error(err.message);
    }

    setIsLoading(false);
  }

  function handleSubmit(arg: FormState) {
    submitLoginForm(arg.values as any);
  }

  return (
    <section className="page-login">
      <div className="login">
        <div className="row">
          <span className="login-greeting">
            Welcome to the <br /> <strong>UN Mobility</strong>
          </span>
        </div>

        <Loader spinning={isLoading} />

        <Form className="login-form" role="form" onSubmit={(arg) => handleSubmit(arg)}>
          <div className="login-field">
            <TextField
              name="email"
              type="email"
              placeholder="Enter your email"
              validate={notEmptyValidator}
            />
          </div>
          <Button className="login-link" text="Sign in" type="submit" variant="link" />
        </Form>
      </div>
      <ToastContainer />
    </section>
  );
};

export default SignInPage;
