/* istanbul ignore file */
import { BFShuttleRoute } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { DatesRangeValue } from '@mantine/dates';
import {
  addDays,
  addMinutes,
  format,
  getDay,
  isBefore,
  max,
  min,
  parse,
  startOfHour,
} from 'date-fns';

export const calcMinDate = (
  date: Date,
  start: string,
  end: string,
  includedDates: string[],
  weekdays: number[],
): Date => {
  const startTime = parse(start, DF.ApiTime, new Date());
  const endTime = parse(end, DF.ApiTime, new Date());
  let minDate = startTime > endTime ? addDays(date, 1) : date;

  const dates = new Set(
    includedDates
      .map((d) => parse(d, DF.ApiDate, new Date()))
      .filter((d) => isBefore(new Date(), d))
      .map((d) => format(d, DF.ApiDate)),
  );

  const isValid = (d: Date) => weekdays.includes(getDay(d) - 1) || dates.has(format(d, DF.ApiDate));

  while (!isValid(minDate)) minDate = addDays(minDate, 1);

  return minDate;
};

export const findRange = (shuttleRoutes: BFShuttleRoute[]): DatesRangeValue => {
  if (!shuttleRoutes || shuttleRoutes.length === 0) {
    return [null, null];
  }

  const minDate = min(shuttleRoutes.map((route) => route.startDate));
  const maxDate = max(shuttleRoutes.map((route) => route.endDate));

  return [minDate, maxDate];
};

export const timeQuarter = (date: Date): Date => {
  const roundedTime = startOfHour(date);
  const roundedMinutes = Math.ceil(date.getMinutes() / 15) * 15;

  return addMinutes(roundedTime, roundedMinutes);
};
