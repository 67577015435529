import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import cookie, { type CookieAttributes } from 'js-cookie';

import { TRANSLATIONS_EN } from './en';

const cookieCrossEnv: CookieAttributes = {
  domain: window.location.hostname.includes('.unbooking.org')
    ? '.unbooking.org'
    : window.location.hostname,
  sameSite: 'None',
  secure: true,
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  initImmediate: false,
  interpolation: { escapeValue: false },
  lng: cookie.get('django_language') || 'en',
  resources: { en: { translation: TRANSLATIONS_EN } },
});

i18n.on('languageChanged', (lang) => {
  cookie.set('django_language', lang, cookieCrossEnv);
});

export default i18n;
