import { format } from 'date-fns';
import { CalChecklistVehicle } from '@common/interfaces';

export const getUnavailability = (vehicle: CalChecklistVehicle): string => {
  let label: string;
  let startDate: Date;
  let endDate: Date;

  if (vehicle.criticalIssue) {
    endDate = new Date(vehicle.criticalIssue.end);
    startDate = new Date(vehicle.criticalIssue.start);
    label = 'Critical Issue';
  } else {
    endDate = new Date(vehicle.currentUnavailability?.untilDate!);
    startDate = new Date(vehicle.currentUnavailability?.sinceDate!);
    label = vehicle.currentUnavailability?.reasonLabel ?? '';
  }

  const monthYear = format(startDate, 'MMMM yyyy');
  const start = format(startDate, 'd');
  const end = format(endDate, 'd');

  return start === end
    ? `${label} on ${start} ${monthYear}!`
    : `${label} from ${start} to ${end} ${monthYear}!`;
};
