/* eslint-disable react/no-array-index-key */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import cn from 'classnames';
import { ArrowRound } from '@assets/svg/icons';
import { Alert, Critical, Issue } from '@assets/svg/icons/checklist';
import { ChecklistAnswer, ChecklistStatus, VehiclesChecklistDetails } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { Button, OutsideClickWrapper } from '@components';
import { getChecklistAnswers, getChecklistStatus } from '../../utils';
import './DriverInfoPopup.styles.scss';

interface DriverPopupProps {
  checklist: VehiclesChecklistDetails;
  onClosePopup: () => void;
}

const Answer = ({ answer }: { answer: ChecklistAnswer }): JSX.Element => (
  <li className="issue">
    {answer.isCritical && <Alert className="critical-issue" />}
    {answer.question}
    {answer.note && <q>{answer.note}</q>}
    {answer.imagesUrl?.map((img, i) => (
      <img src={img} alt="issue" key={i} />
    ))}
  </li>
);

export const DriverInfoPopup = ({ checklist, onClosePopup }: DriverPopupProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const answers = getChecklistAnswers(checklist);
  const { status } = getChecklistStatus(checklist);
  const { driverFirstName, driverLastName } = checklist;
  const isCritical = status === ChecklistStatus.CRITICAL;
  const isIssue = status === ChecklistStatus.ISSUES;
  const issues = answers.filter((i) => i.answer !== 'yes');
  const key = isIssue ? 'mobility.issuesTextVehicleCanStart' : 'mobility.issuesTextBeforeStarting';
  const driverName =
    driverFirstName && driverLastName ? `${driverFirstName} ${driverLastName}` : 'Unknown';

  return (
    <OutsideClickWrapper onClickOutside={onClosePopup}>
      <aside className="driver-info-popup" data-testid="driver-info-popup">
        <header className={cn('header', { critical: isCritical, issue: isIssue })}>
          <div className={cn('icons', { critical: isCritical, issue: isIssue })}>
            {isIssue ? <Issue /> : <Critical />}
          </div>
          <h2>{isIssue ? t('mobility.issues') : t('mobility.criticalIssue')}</h2>
          <button type="button" onClick={onClosePopup}>
            <ArrowRound className="close-icon" type="bottom" />
          </button>
        </header>

        <div className="content">
          <div className="main-info">
            <h4 className="date">
              {format(parse(checklist.created, DF.ApiDateAlt, new Date()), DF.InvoiceInfoDate)}
            </h4>
            <h5>{t('mobility.checklistCompletedByDriver')}</h5>
            <h3 className="driver">{driverName}</h3>
            <h5>{t('mobility.forVehicle')}</h5>
            <div className="vehicle">
              <div className="image">
                <img src={checklist.vehicleImage} alt="Vehicle" />
              </div>
              <h4>
                {checklist.vehicleModelName} / {checklist.plateNumber}
              </h4>
            </div>
            <h5 className="description">{t(key)}</h5>
          </div>

          <div className="issues">
            {issues.length > 4 ? (
              <>
                {issues.slice(0, 5).map((answer, i) => (
                  <Answer answer={answer} key={i} />
                ))}
                <p>
                  + {issues.length - 4} {t('mobility.otherIssues')}
                </p>
              </>
            ) : (
              issues.map((answer, i) => <Answer answer={answer} key={i} />)
            )}
          </div>

          <div className="footer">
            <Button
              text={t('mobility.btnGoToChecklist')}
              variant="outline"
              onClick={() => navigate(`${checklist.id}`)}
            />
          </div>
        </div>
      </aside>
    </OutsideClickWrapper>
  );
};
