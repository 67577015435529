import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainMenu } from '@unbooking/ui-modules';
import { useFacility } from '@common/hooks';
import { Loader } from '@components';
import { Charts, CostRecoverySummary, CountryReporting } from '@assets/svg/icons';
import { DriverLogo } from '@assets/svg/logos';
import './styles.scss';

const ReportingAreaMenuPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { agencyName, facility, isLoading } = useFacility();
  const { city, country, facilityName } = facility;

  const menuButtons = [
    {
      className: 'btn-country-reporting',
      icon: <CountryReporting />,
      label: t('report.countryDemand'),
      onClick: () => navigate('country-reporting'),
    },
    {
      className: 'btn-cost-recovery-summary',
      icon: <CostRecoverySummary />,
      label: t('report.costRecoverySummary'),
      onClick: () => {
        sessionStorage.setItem('selectedTabCostRecoveryReport', 'Summary');
        navigate('cost-recovery-report');
      },
    },
    {
      className: 'btn-dashboard',
      icon: <Charts />,
      label: t('crumbs.dashboard'),
      onClick: () => navigate('dashboard'),
    },
  ];

  return (
    <Loader color="white" fullScreen spinning={isLoading}>
      <MainMenu
        logo={<DriverLogo />}
        menuButtons={menuButtons}
        title={
          city && country && !isLoading
            ? `${facilityName}, ${city}, ${country} | ${agencyName}`
            : ''
        }
      />
    </Loader>
  );
};

export default ReportingAreaMenuPage;
