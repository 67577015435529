/* istanbul ignore file */
import { PropsWithChildren } from 'react';
import { AuthProvider as Auth, TAuthConfig } from '@unbooking/ui-auth';
import { axios } from '@common/utils';

const getEnv = (key: string, fallback = '') => process.env[key] ?? fallback;

const scope = ['email', 'openid', 'profile'].join(' ');

export const authConfig: TAuthConfig = {
  clientId: getEnv('REACT_APP_AUTH_CLIENT_ID'),
  authorizationEndpoint: getEnv('REACT_APP_AUTH_AUTHORIZATION_ENDPOINT'),
  tokenEndpoint: getEnv('REACT_APP_AUTH_TOKEN_ENDPOINT'),
  refreshTokenEndpoint: getEnv('REACT_APP_AUTH_REFRESHTOKEN_ENDPOINT'),
  redirectUri: getEnv('REACT_APP_AUTH_REDIRECT_URI'),
  logoutEndpoint: getEnv('REACT_APP_AUTH_LOGOUT_ENDPOINT'),
  logoutUri: getEnv('REACT_APP_AUTH_LOGOUT_URI'),
  unbhApiUrl: getEnv('REACT_APP_HBH_API'),
  unbhApiKey: getEnv('REACT_APP_HBH_API_KEY'),
  scope,
  configurations: {
    b2c: {
      authorizationEndpoint: getEnv('REACT_APP_B2C_AUTHORIZATION_ENDPOINT'),
      tokenEndpoint: getEnv('REACT_APP_B2C_TOKEN_ENDPOINT'),
      clientId: getEnv('REACT_APP_B2C_CLIENT_ID'),
      logoutEndpoint: getEnv('REACT_APP_B2C_LOGOUT_ENDPOINT'),
      logoutUri: getEnv('REACT_APP_B2C_LOGOUT_URI'),
      scope: `${scope} offline_access`,
    },
    google: {
      authorizationEndpoint: getEnv('REACT_APP_GOOGLE_AUTHORIZATION_ENDPOINT'),
      tokenEndpoint: getEnv('REACT_APP_GOOGLE_TOKEN_ENDPOINT'),
      clientId: getEnv('REACT_APP_GOOGLE_AUTH_CLIENT_ID'),
      clientSecret: getEnv('REACT_APP_GOOGLE_AUTH_CLIENT_SECRET'),
      scope,
    },
  },
};

const AuthProvider = ({ children }: PropsWithChildren) => (
  <Auth authConfig={authConfig} axiosInstance={axios}>
    {children}
  </Auth>
);

export default AuthProvider;
