import { useCallback, useEffect, useRef, useState } from 'react';
import { ChatIconClosed, ChatIconOpened } from './ChatIcons';
import css from './WfpChatbot.module.scss';

const FOOTER_HEIGHT = 60;

export interface WfpChatbotProps {
  iframeSrc: string;
  thread: string;
  token: string;
  user: string;
}

export function WfpChatbot({ iframeSrc, thread, token, user }: WfpChatbotProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [offset, setOffset] = useState(30);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const updateOffset = useCallback(() => {
    const { innerHeight, scrollY } = window;
    const documentHeight = document.documentElement.scrollHeight;
    const atPageBottom = scrollY + innerHeight >= documentHeight - FOOTER_HEIGHT;
    setOffset(documentHeight <= innerHeight || atPageBottom ? FOOTER_HEIGHT + 10 : 10);
  }, []);

  useEffect(() => {
    const mutationObserver = new MutationObserver(updateOffset);
    const resizeObserver = new ResizeObserver(updateOffset);

    updateOffset();
    window.addEventListener('scroll', updateOffset);
    window.addEventListener('resize', updateOffset);
    mutationObserver.observe(document.body, { childList: true, subtree: true });
    resizeObserver.observe(document.documentElement);

    return () => {
      window.removeEventListener('scroll', updateOffset);
      window.removeEventListener('resize', updateOffset);
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [updateOffset]);

  useEffect(() => {
    if (!iframeRef.current) return;
    iframeRef.current.onload = () => {
      iframeRef.current?.contentWindow?.postMessage({ token, user, thread }, iframeRef.current.src);
    };
  }, [isActive, thread, token, user]);

  const toggleChatbot = () => {
    setIsActive(true);
    setIsOpen((x) => !x);
  };

  return (
    <aside
      className={`${css.chatbot} ${isActive && css.animate} ${isOpen ? css.opened : css.closed}`}
      style={{ bottom: offset }}
      data-testid="chatbot_container"
    >
      <div className={css['chatbot-wrap']}>
        {isActive && (
          <iframe
            className={css['chatbot-iframe']}
            ref={iframeRef}
            src={iframeSrc}
            sandbox="allow-scripts allow-same-origin"
            title="chatbot"
            height="100%"
            width="100%"
            data-testid="chatbot_iframe"
          />
        )}
      </div>
      <div className={css['btn-wrap']}>
        <button className={css.btn} type="button" onClick={toggleChatbot} data-testid="chatbot_btn">
          <span>Chat with us</span>
          <div className={css['icon-wrap']}>
            <div className={css['icon-slider']}>
              <ChatIconOpened />
              <ChatIconClosed />
            </div>
          </div>
        </button>
      </div>
    </aside>
  );
}

export default WfpChatbot;
