/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { useAuth } from '@unbooking/ui-auth';
import { RepositoryProvider } from '@context';
import { SessionStorageKeys } from '@common/constants';
import { Crumb, HeaderVariant } from '@common/interfaces';
import { Chatbot, Footer, Header, MaintenanceBanner } from '@components';
import { CallbackPage, ErrorPage, NoAccessPage, SignInPage, NotFoundPage } from '@pages';
import { useFacilityPermission, useTranslations, useUserInfo } from '@common/hooks';
import { RootLayout } from '@layout';
import { useTranslation } from 'react-i18next';
import { routes } from './routes';
import pkg from '../package.json';

const BOOKING_FORM_URL = process.env.REACT_APP_BOOKING_FORM_URL;
const PRIVATE_VEHICLE_FORM = process.env.REACT_APP_PRIVATE_VEHICLE_FORM_URL;
const DO_URL = process.env.REACT_APP_DO_URL;
const MAINTENANCE_BANNER = process.env.REACT_APP_MAINTENANCE_SWITCH;
const UNDSS_URL = process.env.REACT_APP_UNDSS_URL;
const UNBH_DO = process.env.REACT_APP_HBH_DO_URL;
const UNBH_HOME = process.env.REACT_APP_UNBH_HOME_LINK;
const LOGOUT_URL = process.env.REACT_APP_AUTH_LOGOUT_URI;

const releaseVersion = `v.${pkg.version} ${
  process.env.REACT_APP_BUILD ? `| rev.${process.env.REACT_APP_BUILD}` : ''
}`;
const traceTargets = process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS
  ? process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS.split(',')
  : undefined;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: ['prod', 'qa'].includes(process.env.REACT_APP_ENV_NAME!),
  environment: process.env.REACT_APP_ENV_NAME,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
    ...(process.env.REACT_APP_ENV_NAME === 'qa'
      ? [
          Sentry.feedbackIntegration({
            colorScheme: 'dark',
            enableScreenshot: false,
            id: 'sentry-feedback',
            isEmailRequired: true,
            isNameRequired: true,
            showBranding: false,
          }),
        ]
      : []),
  ],
  release: releaseVersion,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.05,
  sampleRate: 0.1,
  tracePropagationTargets: traceTargets,
  tracesSampleRate: 0.1,
});

const RouterElement = ({ route }: { route: Crumb }) => (
  <RootLayout
    routes={routes}
    route={route}
    homeUrl={DO_URL!}
    node={
      <>
        <ToastContainer />
        <Chatbot />
      </>
    }
  />
);

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { axios, isAuthenticated, isLoginInProgress, logout } = useAuth();
  const { firstName, isDfp, lastName } = useUserInfo();
  const { i18n } = useTranslation();

  useFacilityPermission();
  useTranslations();

  const goToHome = (params?: string) =>
    navigate(params ? `/${location.search}` : '/', { replace: true });

  const path = window.location.pathname;
  const isLogoutUrl = window.location.href.startsWith(`${LOGOUT_URL}`);
  const isDDORoute = path.startsWith(`${DO_URL}`);
  const isPrivateVehicleForm =
    path === `/${PRIVATE_VEHICLE_FORM}` || path.startsWith(`/${PRIVATE_VEHICLE_FORM}/`);
  const isNestedRoute =
    path.startsWith(`/${UNDSS_URL}/profile`) ||
    path.startsWith(`/${BOOKING_FORM_URL}/`) ||
    isPrivateVehicleForm;

  useEffect(() => {
    if (
      !isLoginInProgress &&
      !sessionStorage.getItem(SessionStorageKeys.AGENCY) &&
      !sessionStorage.getItem(SessionStorageKeys.FACILITY) &&
      !isDDORoute &&
      !isNestedRoute
    ) {
      goToHome();
    }

    if (!isAuthenticated && (isLogoutUrl || isNestedRoute || isDDORoute)) {
      goToHome(location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RepositoryProvider axios={axios}>
      {isAuthenticated ? (
        <>
          <MaintenanceBanner switchName={MAINTENANCE_BANNER!} />
          <Header
            activeLang={i18n.language}
            languages={['en', 'fr']}
            logout={logout}
            username={`${firstName} ${lastName}`}
            isOffice={isDDORoute}
            officeUrl={isDfp ? DO_URL : null}
            onLangChange={i18n.changeLanguage}
            onLogoClick={() => window.open(`${isDDORoute ? UNBH_DO : UNBH_HOME}`, '_self')}
            variant={isPrivateVehicleForm ? HeaderVariant.MODEST : HeaderVariant.DEFAULT}
          />
        </>
      ) : null}

      <Routes>
        {isAuthenticated ? (
          routes.map((route) => (
            <Route path={route.path} key={route.path} element={<RouterElement route={route} />} />
          ))
        ) : (
          <Route index element={<SignInPage />} />
        )}
        <Route path="callback/*" element={<CallbackPage />} />
        <Route path="forbidden" element={<NoAccessPage />} />
        <Route path="error" element={<ErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {isAuthenticated ? <Footer releaseVersion={releaseVersion} /> : null}
    </RepositoryProvider>
  );
}

export default App;
