import { type FC, SVGProps, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import {
  endOfMonth,
  endOfWeek,
  format,
  getMonth,
  getYear,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import FullCalendar, { DatesSetArg, EventContentArg } from '@fullcalendar/react';
import type { DatesRangeValue } from '@mantine/dates';
import { Dropdown } from '@ui-modules/informed';
import { MONTHS, YEARS } from '@common/constants';
import { useRepository } from '@context';
import { Button, DateInputPicker, Loader } from '@components';
import {
  ClearSmall,
  CriticalSmall,
  Details,
  IssuesSmall,
  NotReportedSmall,
} from '@assets/svg/icons/checklist';
import {
  CalVehiclesChecklistResource,
  ChecklistStatus,
  DropdownOption,
  ProtType,
  VehicleChecklistDetailsApi,
  VehiclesChecklist,
  VehiclesChecklistDetails,
} from '@common/interfaces';
import { useDrivers, useFacility, useVehicles } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { getErrors } from '@common/utils';
import { CalendarVehiclesChecklist, DriverInfoPopup } from './components';
import { getChecklistStatus, getUnavailability, Serializer } from './utils';
import './VehiclesChecklistPage.styles.scss';

const VehiclesChecklistPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { vehicleChecklistRepo } = useRepository();
  const { driverOptions } = useDrivers();
  const { isLoading: isVehiclesLoading, vehicleOptions, vehicles } = useVehicles();
  const {
    agencyName,
    facility: { agencyId, city, country, uuid: fid },
    facilityId: facilityIdOnUNBH,
    isLoading: isFacilityLoading,
  } = useFacility();

  const calendarRef = useRef<FullCalendar | null>(null);
  const currentWeek = startOfWeek(new Date());
  const weekStartDate = format(currentWeek, DF.ApiDate);
  const weekEndDate = format(endOfWeek(currentWeek), DF.ApiDate);

  const [currentDate, setCurrentDate] = useState<Date>(currentWeek);
  const [createdAfter, setCreatedAfter] = useState<string>(weekStartDate);
  const [createdBefore, setCreatedBefore] = useState<string>(weekEndDate);
  const [month, setOverviewMonth] = useState<number>(getMonth(new Date()));
  const [year, setOverviewYear] = useState<number>(getYear(new Date()));

  const [selectedDriver, setSelectedDriver] = useState<DropdownOption>();
  const [selectedVehicle, setSelectedVehicle] = useState<DropdownOption>();

  const [selectedChecklistId, setSelectedChecklistId] = useState<string | null>(null);
  const [checklistDetails, setChecklistDetails] = useState<VehiclesChecklistDetails | null>();

  const checklistQuery = useQuery(
    'get-checklists',
    async () =>
      vehicleChecklistRepo.getChecklists(facilityIdOnUNBH, {
        agencyId,
        createdAfter,
        createdBefore,
        driverId: selectedDriver?.value,
        locationId: fid,
      }),
    { enabled: false },
  );
  const { data: checklistData, isLoading: isChecklistLoading, refetch } = checklistQuery;

  useQuery(
    ['get-checklist-details', selectedChecklistId],
    () => vehicleChecklistRepo.getChecklistDetails(facilityIdOnUNBH, selectedChecklistId!),
    {
      enabled: !!selectedChecklistId,
      onSuccess(data: VehicleChecklistDetailsApi) {
        setChecklistDetails(Serializer.formatChecklistDetails(data));
      },
      onError(err: any) {
        toast.error(getErrors(err.response));
      },
    },
  );

  const { data: overview, refetch: getChecklistSummary } = useQuery(
    [`get-checklists-summary`, month, year],
    () =>
      vehicleChecklistRepo.getChecklistSummary(facilityIdOnUNBH, {
        agencyId,
        createdAfter: format(startOfMonth(new Date(year, month)), DF.ApiDate),
        createdBefore: format(endOfMonth(new Date(year, month)), DF.ApiDate),
        driverId: selectedDriver?.value,
        locationId: fid,
      }),
    { enabled: false },
  );

  const handleViewChange = ({ startStr }: DatesSetArg): void => {
    if (!isSameDay(currentDate, parseISO(startStr))) setCurrentDate(parseISO(startStr));
  };

  const renderEventContent = ({ event: { extendedProps } }: EventContentArg): JSX.Element => {
    const { status, statusName } = getChecklistStatus(extendedProps as VehiclesChecklist);
    const { driverFirstName, driverLastName, id } = extendedProps;
    const driverFullName = `${driverFirstName ?? '\u00A0'} ${driverLastName ?? '\u00A0'}`;

    const iconsMapper: Record<string, FC<SVGProps<SVGSVGElement>> & { title?: string }> = {
      clear: ClearSmall,
      issues: IssuesSmall,
      critical: CriticalSmall,
      'not-reported': NotReportedSmall,
    };

    const ChecklistIcon = iconsMapper[status];

    const handleDoubleClick = () => {
      if (status === 'issues' || status === 'critical') {
        setSelectedChecklistId(id);
      } else {
        navigate(`${id}`);
      }
    };

    return (
      <figure
        className={`fc-event-content fc-event-content-${status}`}
        data-testid={`vehicles-checklist-event-${status}`}
        onDoubleClick={handleDoubleClick}
      >
        {status === ChecklistStatus.NOT_USED ? (
          <div>{statusName}</div>
        ) : (
          <>
            <div className={`fc-event-header fc-event-header-${status}`}>{statusName}</div>
            <div className="fc-event-desc">
              <h4>{driverFullName}</h4>
              <div className="fc-event-desc-status">
                <div className={`fc-event-desc-icon fc-event-desc-icon-${status}`}>
                  <ChecklistIcon />
                </div>
                <Details className="fc-event-desc-icon-details" />
              </div>
            </div>
          </>
        )}
      </figure>
    );
  };

  const renderResourceContent = ({ resource }: CalVehiclesChecklistResource): JSX.Element => {
    const { extendedProps: ext } = resource;
    const { criticalIssue, currentUnavailability: current, model, plateNumber, protection } = ext;

    return (
      <section className="fc-resource-content" data-testid="vehicles-checklist-calendar-resource">
        <div className="fc-resource-content-vehicle-image">
          <img src={model?.image ?? undefined} alt={plateNumber} />
          <div className="fc-resource-content-vehicle-protection">
            {protection === ProtType.Soft ? 'Soft' : 'Armoured'}
          </div>
        </div>
        <div className="fc-resource-content-desc">
          <h3>
            {model?.name} <br /> {plateNumber}
            {current || criticalIssue ? (
              <p className={`${current ? 'issues' : ''} ${criticalIssue ? 'critical' : ''}`}>
                {getUnavailability(ext)}
              </p>
            ) : null}
          </h3>
        </div>
      </section>
    );
  };

  useEffect(() => {
    if (agencyId && fid) refetch();
  }, [agencyId, createdAfter, createdBefore, fid, refetch, selectedDriver]);

  useEffect(() => {
    if (agencyId && fid && year) getChecklistSummary();
  }, [agencyId, fid, getChecklistSummary, month, year]);

  useEffect(() => {
    if (currentDate) {
      setCreatedBefore(format(endOfWeek(currentDate), DF.ApiDate));
      setCreatedAfter(format(currentDate, DF.ApiDate));
    }
  }, [currentDate]);

  const headerToolbarCalendar = useMemo(
    () => (
      <div data-testid="vehicles-checklist-calendar-date-picker">
        <DateInputPicker
          firstDayOfWeek={0}
          format="DD MMM YY"
          iconPosition="left"
          showIconOnly
          type="range"
          value={[currentDate, endOfWeek(currentDate)]}
          variant="light"
          onChange={(v) => {
            const date = v as DatesRangeValue;
            const currentWeekStartDate = date[0] && startOfWeek(date[0]);

            if (currentWeekStartDate) {
              calendarRef.current?.getApi().gotoDate(currentWeekStartDate);
              setCurrentDate(currentWeekStartDate);
            }
          }}
        />
      </div>
    ),
    [currentDate],
  );

  const calendarChecklistEvents = useMemo(
    () => Serializer.mapChecklistEventsForCalendar(checklistData?.results ?? []),
    [checklistData],
  );

  const visibleVehicles = useMemo(
    () => (selectedVehicle ? vehicles.filter(({ id }) => selectedVehicle.value === id) : vehicles),
    [selectedVehicle, vehicles],
  );

  const calendarVehicles = useMemo(
    () => Serializer.mapVehiclesForScheduler(visibleVehicles, checklistData?.results ?? []),
    [checklistData, visibleVehicles],
  );

  return (
    <section className="vehicles-checklist">
      <Loader fullScreen spinning={isChecklistLoading || isFacilityLoading || isVehiclesLoading}>
        <header className="vehicles-checklist-header">
          <section className="vehicles-checklist-filters">
            <h1>
              {`${t('mobility.vehiclesChecklist')} - `}
              <span>
                {agencyName} {country}, {city}
              </span>
            </h1>

            <Form className="filters">
              <div className="driver">
                <Dropdown
                  className="dropdown outline"
                  name="filterByDriver"
                  options={driverOptions}
                  placeholder={t('mobility.filterByDriver')}
                  value={selectedDriver}
                  onChange={({ value }) => {
                    setSelectedDriver(driverOptions.find(({ value: id }) => id === value));
                  }}
                />
              </div>
              <div className="vehicle">
                <Dropdown
                  className="dropdown outline"
                  name="filterByVehicle"
                  options={vehicleOptions}
                  placeholder={t('mobility.filterByVehicle')}
                  value={selectedVehicle}
                  onChange={({ value }) => {
                    setSelectedVehicle(vehicleOptions.find(({ value: id }) => id === value));
                  }}
                />
              </div>
              <Button
                className="btn btn-reset"
                text={t('common.btnResetFilters')}
                variant="link"
                onClick={() => {
                  setSelectedDriver(undefined);
                  setSelectedVehicle(undefined);
                }}
              />
            </Form>
          </section>

          <section className="vehicles-checklist-info">
            <section className="vehicles-checklist-info-overview">
              <div>
                <Form className="filters">
                  <div className="overview">
                    <h3>{t('mobility.overviewFor')}</h3>
                    <Dropdown
                      className="dropdown outline"
                      defaultValue={MONTHS.find(({ value }) => value === format(new Date(), 'M'))}
                      name="overviewMonth"
                      options={MONTHS}
                      onChange={(option: DropdownOption) => setOverviewMonth(+option.value - 1)}
                    />
                    <Dropdown
                      className="dropdown outline"
                      defaultValue={YEARS.find(({ value }) => value === format(new Date(), 'yyyy'))}
                      name="overviewYear"
                      options={YEARS}
                      onChange={(option: DropdownOption) => setOverviewYear(+option.value)}
                    />
                  </div>
                </Form>

                <ul className="checklist">
                  <li className="clear">
                    {overview?.aggregated.clear_number ?? 0} {t('mobility.clear')}
                  </li>
                  <li className="critical">
                    {overview?.aggregated.critical_issues_number ?? 0} {t('mobility.critical')}
                  </li>
                  <li className="not-reported">
                    {overview?.aggregated.not_submitted_number ?? 0} {t('mobility.notReported')}
                  </li>
                  <li className="issues">
                    {overview?.aggregated.issues_number ?? 0} {t('mobility.issues')}
                  </li>
                  <li className="not-used">
                    {overview?.aggregated.not_used ?? 0} {t('mobility.notUsed')}
                  </li>
                </ul>
              </div>

              <div className="daily-checks">
                <h2>{overview?.aggregated.submitted_number ?? 0}</h2>
                <small>{t('mobility.submitted')}</small>
              </div>
            </section>
          </section>
        </header>

        <main>
          {visibleVehicles.length ? (
            <CalendarVehiclesChecklist
              eventContent={renderEventContent}
              events={calendarChecklistEvents}
              headerToolbarCalendar={headerToolbarCalendar}
              ref={calendarRef}
              resourceContent={renderResourceContent}
              resources={calendarVehicles}
              onViewChange={handleViewChange}
            />
          ) : null}

          {checklistDetails && (
            <DriverInfoPopup
              checklist={checklistDetails}
              onClosePopup={() => {
                setChecklistDetails(null);
                setSelectedChecklistId(null);
              }}
            />
          )}
        </main>
      </Loader>
    </section>
  );
};

export default VehiclesChecklistPage;
