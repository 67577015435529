/* eslint-disable @typescript-eslint/no-use-before-define */
import { useAuth } from '@unbooking/ui-auth';
import { useMonolithFeatureFlags } from '@common/hooks';
import { WfpChatbot } from './WfpChatbot';

const role2TokenMap = {
  DRIVER: '743f36da-966f-4401-8920-47cb495f51b7',
  FP: '743f36da-966f-4401-8920-47cb495f51b7',
  PASSENGER: '6932c570-f526-4c77-b6c1-4b241e3597db',
};

export function Chatbot() {
  const { flags } = useMonolithFeatureFlags();
  const { refreshToken = '', userEmail = '' } = useAuth();

  if (!flags.show_chatbot?.is_active) return null;

  return (
    <WfpChatbot
      iframeSrc={process.env.REACT_APP_CHATBOT_HOST_URL ?? ''}
      thread={role2TokenMap.PASSENGER}
      token={refreshToken}
      user={userEmail}
    />
  );
}

export default Chatbot;
