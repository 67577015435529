import { forwardRef, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import FullCalendar, {
  CustomContentGenerator,
  DatesSetArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { CalChecklistVehicle, CalVehiclesChecklistResource } from '@common/interfaces';
import './Calendar.styles.scss';

interface CalendarProps {
  eventContent?: CustomContentGenerator<EventContentArg>;
  events: EventSourceInput;
  headerToolbarCalendar?: JSX.Element;
  resourceContent?: CustomContentGenerator<CalVehiclesChecklistResource>;
  resources?: CalChecklistVehicle[];
  onDoubleClick?: (resourceId: string) => void;
  onViewChange?: (payload: DatesSetArg) => void;
}

const CalendarVehiclesChecklist = forwardRef<FullCalendar, CalendarProps>(
  (
    {
      eventContent,
      events,
      headerToolbarCalendar,
      resourceContent,
      resources,
      onDoubleClick,
      onViewChange,
    },
    calendarRef,
  ) => {
    const doubleClickTimeout = useRef<NodeJS.Timeout | null>(null);
    const [isRendered, setRendered] = useState(false);

    useEffect(() => {
      if (!headerToolbarCalendar) return;

      const container = document.createElement('div');
      const toolbarContainer = document.getElementsByClassName('fc-toolbar-chunk')[1];
      if (!toolbarContainer) return;

      container.className = 'fc-toolbar-calendar';
      createRoot(container).render(headerToolbarCalendar);

      if (toolbarContainer.hasChildNodes() && isRendered) toolbarContainer.innerHTML = '';
      toolbarContainer.appendChild(container);

      setRendered(true);

      return () => setRendered(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerToolbarCalendar]);

    const handleDateClick = ({ resource }: { resource?: { id: string } }) => {
      if (!onDoubleClick) return;

      if (doubleClickTimeout.current) {
        clearTimeout(doubleClickTimeout.current);
        doubleClickTimeout.current = null;
        onDoubleClick(resource?.id || '');
        return;
      }

      doubleClickTimeout.current = setTimeout(() => {
        doubleClickTimeout.current = null;
      }, 200);
    };

    return (
      <div className="fc-calendar-vehicles-checklist">
        <FullCalendar
          contentHeight="auto"
          datesSet={(payload: DatesSetArg) => onViewChange?.(payload)}
          dateClick={handleDateClick}
          dayMaxEvents
          eventContent={eventContent}
          eventOverlap={false}
          events={events}
          headerToolbar={{ left: 'title prev next', center: '', right: '' }}
          initialView="resourceTimelineWeek"
          locale="en-GB" // 00:00 to 24:00
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          ref={calendarRef}
          resources={resources}
          resourceAreaHeaderContent="&nbsp;"
          resourceAreaWidth="310px"
          resourceLabelContent={resourceContent}
          resourceOrder="" // disable sorting by default
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          slotDuration={{ days: 1 }}
          slotLabelFormat={{
            day: 'numeric', // Display numeric day (e.g., 1, 2)
            weekday: 'short', // Display short weekday names (e.g., Mon, Tue)
          }}
          stickyHeaderDates
          titleFormat={{ day: 'numeric', month: 'short', year: 'numeric' }}
        />
      </div>
    );
  },
);

CalendarVehiclesChecklist.displayName = 'CalendarVehiclesChecklist';

export default CalendarVehiclesChecklist;
