/* eslint-disable @typescript-eslint/no-unused-vars */
import { type FC, createContext, useContext, useState, useMemo } from 'react';
import { SessionStorageKeys } from '@common/constants';
import { DropdownOption, FeatureFlags, IFacility } from '@common/interfaces';

const initialState = {
  agencyId: '',
  agencyName: (sessionStorage.getItem(SessionStorageKeys.AGENCY) as string) || '',
  facility: {
    agency: '',
    agencyId: '',
    categoryName: '',
    city: '',
    country: '',
    countryCode: '',
    email: '',
    facilityName: '',
    isActive: false,
    isQuest: false,
    locationFileCategories: [] as Array<'documents' | 'manuals' | 'trainings'>,
    sharedWithAgencies: [] as DropdownOption[],
    uuid: '',
  },
  facilityId: (sessionStorage.getItem(SessionStorageKeys.FACILITY) as string) || '',
  featureFlags: {
    flags: {},
    samples: {},
    switches: {},
  },
  setAgencyId: (_: string) => {},
  setAgencyName: (_: string) => {},
  setFacility: (_: IFacility) => {},
  setFacilityId: (_: string) => {},
  setFeatureFlags: (_: FeatureFlags) => {},
};

export const GlobalContext = createContext(initialState);

interface GlobalProviderProps {
  children?: React.ReactNode;
}

const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
  const [agencyId, setAgencyId] = useState<string>(initialState.agencyId);
  const [agencyName, setAgencyName] = useState<string>(initialState.agencyName);
  const [facility, setFacility] = useState<IFacility>(initialState.facility);
  const [facilityId, setFacilityId] = useState<string>(initialState.facilityId);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(initialState.featureFlags);

  const contextValue = useMemo(
    () => ({
      agencyId,
      agencyName,
      facility,
      facilityId,
      featureFlags,
      setAgencyId,
      setAgencyName,
      setFacility,
      setFacilityId,
      setFeatureFlags,
    }),
    [agencyId, agencyName, facility, facilityId, featureFlags],
  );

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
