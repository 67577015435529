import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@unbooking/ui-modules';
import { BookingRoute, BookingRouteReject, Bookings, BookingState } from '@common/interfaces';
import { Button } from '@components';

type RouteTableProps = {
  agencyId: string;
  data: Bookings;
  rejectRoute: (route: BookingRouteReject) => void;
};

const RouteTable = ({ agencyId, data, rejectRoute }: RouteTableProps) => {
  const { t } = useTranslation();
  const { agencyFk, id, routes, state } = data;

  const renderLocation = (location: string, town: string) => (
    <div>
      {location}
      <div className="sub">{town}</div>
    </div>
  );

  const renderTime = (date: string, time?: string) => (
    <div>
      {date}
      {time && <div className="sub">{time}</div>}
    </div>
  );

  const renderRejectBtn = (route: BookingRoute) => {
    if (state !== BookingState.NotProcessed) return null;
    if (route.routeState !== BookingState.NotProcessed)
      return <span className="route-state">{route.routeState.replace('_', ' ')}</span>;

    return (
      agencyFk === agencyId && (
        <Button
          className="btn-reject"
          text={t('bookingDetails.btnReject')}
          variant="filled"
          onClick={() =>
            rejectRoute({ agencyId: agencyFk, bookingId: id, route: route.bookedRouteOrder })
          }
        />
      )
    );
  };

  const columns = [
    { key: '', render: (r: BookingRoute) => <>#{r.bookedRouteOrder}</> },
    {
      key: 'pickup',
      title: t('bookingDetails.pickupDate'),
      render: (r: BookingRoute) => renderTime(r.pickupDate, r.pickupTime),
    },
    {
      key: 'pickupLocation',
      title: t('bookingDetails.pickupLocation'),
      ellipsis: { showTitle: true },
      render: (r: BookingRoute) => renderLocation(r.pickupLocation, r.pickupTown),
    },
    {
      key: 'dropoffDate',
      title: t('bookingDetails.dropoffDate'),
      render: (r: BookingRoute) =>
        renderTime(r.dropoffDate, state !== BookingState.NotProcessed ? r.dropoffTime : undefined),
    },
    {
      key: 'dropoffLocation',
      title: t('bookingDetails.dropoffLocation'),
      ellipsis: { showTitle: true },
      render: (r: BookingRoute) => renderLocation(r.dropoffLocation, r.dropoffTown),
    },
    ...(state !== BookingState.NotProcessed
      ? [
          { dataIndex: 'driver', key: 'driver', title: t('common.driver') },
          { dataIndex: 'vehicle', key: 'vehicle', title: t('mobility.plateNumber') },
          { dataIndex: 'stateDisplay', key: 'stateDisplay', title: t('common.status') },
        ]
      : []),
    { key: 'ctrl', title: '', render: renderRejectBtn },
  ];

  return (
    <div className="table-routes">
      <Table columns={columns} data={routes} rowKey="bookedRouteOrder" variant="dark" />
    </div>
  );
};

export default memo(RouteTable);
