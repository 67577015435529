/* istanbul ignore file */
import { VehicleType } from './MobilityApi';

export enum ChecklistStatus {
  CLEAR = 'clear',
  CRITICAL = 'critical',
  ISSUES = 'issues',
  NOT_REPORTED = 'not-reported',
  NOT_USED = 'not-used',
}

export interface CalChecklistEvent {
  className: string;
  end: Date;
  extendedProps?: VehiclesChecklist;
  id: string;
  resourceId: string;
  start: Date;
}

export interface CalChecklistVehicle extends VehicleType {
  criticalIssue?: {
    start: string;
    end: string;
  };
  key: string;
}

export interface CalVehiclesChecklistResource {
  resource: {
    extendedProps: CalChecklistVehicle;
    id: string;
  };
}

export interface ChecklistAnswerApi {
  answer: 'yes' | 'no';
  category__name?: string;
  id?: number;
  image_url?: string;
  images_url?: string[];
  is_critical: boolean;
  note: string;
  question: string;
  question_choice?: {
    yes: 'Yes';
    no: 'No';
  };
  tooltip?: string;
}

export interface ChecklistAnswer {
  answer: 'yes' | 'no';
  categoryName?: string;
  id?: number;
  imageUrl?: string;
  imagesUrl?: string[];
  isCritical: boolean;
  note: string;
  question: string;
  questionChoice?: {
    yes: 'Yes';
    no: 'No';
  };
  tooltip?: string;
}

export interface ChecklistQuestionApi {
  category__name: string;
  category__name_fr: string;
  id: number;
  is_critical: boolean;
  question_en: string;
  question_fr: string;
  tooltip: string;
}

export interface ChecklistQuestion {
  categoryName: string;
  id: number;
  isCritical: boolean;
  questionEn: string;
  questionFr: string;
  tooltip: string;
}

export interface ChecklistTab extends VehiclesChecklist {
  main: boolean;
}

export interface VehicleChecklistAgg {
  aggregated: {
    clear_number: number;
    critical_issues_number: number;
    issues_number: number;
    not_submitted_number: number;
    not_used: number;
    submitted_number: number;
  };
  count: number;
  next: string;
  previous: string;
  results: VehiclesChecklistApi[];
}

export interface VehicleChecklistSummary {
  aggregated: {
    clear_number: number;
    critical_issues_number: number;
    issues_number: number;
    not_submitted_number: number;
    not_used: number;
    submitted_number: number;
  };
}

export interface VehiclesChecklistApi {
  agency_logo: string;
  agency_short_name: string;
  city_lat: string | null;
  city_long: string | null;
  city_name: string | null;
  country_iso_code: string | null;
  created: string;
  critical_issues_count: number;
  driver_first_name: string | null;
  driver_id: string | null;
  driver_last_name: string | null;
  id: string;
  issues_count: number;
  location_id: string | null;
  odometer_format: string | null;
  odometer_value: string | null;
  plate_number: string;
  submitted: boolean;
  submitted_at: string | null;
  today_drives_count: number;
  vehicle_id: string;
  vehicle_image: string;
  vehicle_model_name: string;
  vehicle_protection: string;
}

export interface VehiclesChecklist {
  agencyLogo: string;
  agencyShortName: string;
  cityLat: string | null;
  cityLong: string | null;
  cityName: string | null;
  countryCode: string | null;
  created: string;
  criticalIssuesCount: number;
  driverFirstName: string | null;
  driverId: string | null;
  driverLastName: string | null;
  id: string;
  issuesCount: number;
  locationId: string | null;
  odometerFormat: string | null;
  odometerValue: string | null;
  plateNumber: string;
  submitted: boolean;
  submittedAt: string | null;
  todayDrivesCount: number;
  vehicleId: string;
  vehicleImage: string;
  vehicleModelName: string;
  vehicleProtection: string;
}

export interface VehicleChecklistDetailsApi extends VehiclesChecklistApi {
  answers?: Record<string, ChecklistAnswerApi[]>;
  questions?: { items: Record<string, ChecklistQuestionApi[]> };
}

export interface VehiclesChecklistDetails extends VehiclesChecklist {
  answers?: Record<string, ChecklistAnswer[]>;
  questions?: { items: Record<string, ChecklistQuestion[]> };
}
