import type { FC } from 'react';
import { Button } from '@components';
import './styles.scss';

const UNBH_HOME = process.env.REACT_APP_UNBH_HOME_LINK;

const NotFoundPage: FC = () => (
  <main className="page-not-found">
    <div className="container">
      <h1>Invalid Shortcut</h1>
      <hr />
      <p>Kindly access the Digital Office from the Homepage of unbooking.org</p>
      <p>If you think something is broken, contact us</p>

      <div className="links">
        <Button
          className="link"
          text="Go to Homepage"
          variant="transparent"
          onClick={() => window.open(UNBH_HOME, '_self')}
        />
        <a className="link" href="mailto:noreply.humanitarianbooking@wfp.org">
          Contact us
        </a>
      </div>
    </div>
  </main>
);

export default NotFoundPage;
