import { useCallback, useState } from 'react';
import useMonolithFeatureFlags from './useMonolithFeatureFlags';

interface Switcher {
  is_active: boolean;
  note: string;
}

interface MaintenanceBannerResponse {
  bannerMsg: string | null;
  closeBanner: () => void;
}

export default function useMaintenanceBanner(switchName: string): MaintenanceBannerResponse {
  const { switches } = useMonolithFeatureFlags();

  const [bannerMsg, setBannerMsg] = useState<string | null>(null);
  const [isBannerClosed, setBannerClosed] = useState<boolean>(() =>
    Boolean(sessionStorage.getItem(switchName)),
  );

  const closeBanner = useCallback(() => {
    setBannerClosed(true);
    setBannerMsg(null);
    sessionStorage.setItem(switchName, 'true');
  }, [switchName]);

  if (!isBannerClosed) {
    const switcher = switches?.[switchName] as Switcher;
    if (switcher?.is_active && switcher?.note) setBannerMsg(switcher.note);
  }

  return { bannerMsg, closeBanner };
}
