import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SessionStorageKeys } from '@common/constants';

interface ParsedParamsResult {
  parsed: boolean;
}

export default function useParamsParse(): ParsedParamsResult {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const agency = query.get('agency');
  const facility = query.get('facility_id');

  useEffect(() => {
    if (agency && facility) {
      sessionStorage.setItem(SessionStorageKeys.AGENCY, agency.split(',')[0]);
      sessionStorage.setItem(SessionStorageKeys.FACILITY, facility);
    }
  }, [agency, facility]);

  return {
    parsed: !!(agency && facility),
  };
}
