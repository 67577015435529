import { ChecklistAnswer, VehiclesChecklistDetails } from '@common/interfaces';

export const getChecklistAnswers = (checklist?: VehiclesChecklistDetails): ChecklistAnswer[] => {
  if (!checklist) return [];
  const { answers, questions } = checklist;

  if (answers && Object.keys(answers).length) return Object.values(answers).flat();

  return questions?.items
    ? Object.values(questions.items).flatMap((category) =>
        category.map((question) => ({
          ...question,
          answer: 'no',
          imagesUrl: [],
          imageUrl: '',
          note: '',
          question: question.questionEn,
          tooltip: question.tooltip,
        })),
      )
    : [];
};
