/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ArrowRound } from '@assets/svg/icons';
import { ChecklistTab, ProtType } from '@common/interfaces';
import { getChecklistStatus } from '@pages/VehiclesChecklistPage/utils';
import './ChecklistTabs.styles.scss';

interface ChecklistTabsProps {
  checklistTabs: ChecklistTab[];
  onTabSelect: (checklistId: string) => void;
}

const ChecklistTabs = ({ checklistTabs = [], onTabSelect }: ChecklistTabsProps) => {
  const { t } = useTranslation();
  const [activeChecklistId, setActiveChecklistId] = useState(checklistTabs[0]?.id);

  const handleTabClick = (checklistId: string) => {
    setActiveChecklistId(checklistId);
    onTabSelect(checklistId);
  };

  return (
    <div className="checklist-tabs">
      {checklistTabs.map((tab) => {
        const { status, statusName } = getChecklistStatus(tab);
        const isActive = tab.id === activeChecklistId;

        return (
          <section
            className="vehicle"
            key={tab.id}
            role="button"
            tabIndex={0}
            onClick={() => handleTabClick(tab.id)}
            data-testid="checklist-tab"
          >
            <div className={cn('vehicle-status', `vehicle-status-${status}`)}>
              {isActive ? (
                <>
                  <span>{t('checklist.statusChecklist')}</span>
                  <div className="controls">
                    <span>{statusName}</span>
                    <ArrowRound type="bottom" />
                  </div>
                </>
              ) : null}
            </div>

            <div className="vehicle-info">
              <div className="main-content">
                <img src={tab.vehicleImage} alt="Vehicle" />
                <p>
                  {tab?.vehicleModelName} <br /> {tab?.plateNumber}
                </p>
              </div>
              <div className="protection">
                {t(tab.vehicleProtection === ProtType.Soft ? 'common.soft' : 'common.armoured')}
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default ChecklistTabs;
